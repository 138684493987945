@import "./colors";

.sidebar {
  width: 6.1rem;
  height: 100%;
  background-color: $darker;
  border-right: solid 2px $dark;
  position: fixed;
  top: 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 1;

  .cls-logo-1 {
    fill: $darker;
  }

  .nav-item {
    height: 5.5rem;
    width: 100%;

    svg {
      width: 2rem;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $light;
    }
    a:hover {
      svg {
        -webkit-filter: drop-shadow(3px 3px 2px $highlight);
        filter: brightness(1118%) drop-shadow(0px 0px 12px $highlight);
      }
      .title {
        -webkit-filter: drop-shadow(3px 3px 2px $highlight);
        filter: brightness(1118%) drop-shadow(0px 0px 12px $highlight);
      }
    }

    a svg.governance {
      width: 2.1rem;
      filter: #A9A7BE;
    }

    a svg.sewagefruit, svg.logout-icon {
      margin-top: 0.8rem;
    }

    a.active svg {
      -webkit-filter: drop-shadow(3px 3px 2px $highlight);
      filter: brightness(1118%) drop-shadow(0px 0px 12px $highlight);
      text-shadow: none;
    }
    .title {
      font-size: 0.75rem;
      margin-top: 2px;
    }
    .title.active {
      -webkit-filter: drop-shadow(3px 3px 2px $highlight);
      filter: brightness(1118%) drop-shadow(0px 0px 12px $highlight);
      text-shadow: none;
    }
  }

  &.mobile {
    width: 5.5rem;
    z-index: 10;
    top: 0;
    svg.menu {
      fill: $white;
    }
    a svg.sewagefruit,
    svg.logout-icon {
      margin-top: 0.8rem;
    }
    .mt-4.nav-item {
      margin-top: 0.5rem !important;
    }
    .nav-item {
      height: 4.5rem;
    }
    &.slide-out {
      left: -5.5rem;
      transition: left 0.6s ease-out;
    }

    &.slide-in {
      left: 0px;
      transition: left 0.6s ease-out;
    }
  }
}
