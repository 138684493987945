@import "./colors";
@import "./fonts";

.farm {
  padding: 3rem 3rem;
  padding-bottom: 2rem;
  background-color: $background;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    .token-price {
      margin-top: 2rem;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .token-price {
      margin-top: 0rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.875rem;
  }

  .data {
    margin-top: 2.5rem;
    svg {
      height: 2rem;
      margin-right: 0.5rem;

      &.ctx-neon {
        filter: drop-shadow(0 0 0.25rem #6450fb);
      }
    }
  }

  .btn {
    background-color: $darker;
    width: 10rem;
    height: 3rem;
    margin-right: 30px;

    &.question {
      border-radius: 2px;
      width: 20px;
      height: 20px;
      margin-right: 0px;
      font-size: 10px;
      padding: 2px;
      margin-left: 5px;
    }
  }

  #connect {
    background-color: $pink;
    width: 15rem;
    height: 3rem;
    border-radius: 0;
  }

  .card-wrapper {
    margin-top: 2rem;
    margin-left: 0rem;
    h2 {
      font-size: 1.375rem;
      margin-bottom: 1.5625rem;
    }

    h3 {
      font-size: 1rem;
    }

    p {
      font-size: 0.875;
    }
    
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
      .use-tcap {
        margin-top: 2rem;
        margin-bottom: 4rem;
      }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      .use-tcap {
        margin-top: 0rem;
        margin-bottom: 0rem;
      }
    }

    .card {
      padding: 3.125rem 2.8125rem;
      min-height: 20.1875rem;

      .cls-1 {
        fill: $darker;
      }

      b {
        font-family: "Nineteen Ninety Seven";
        color: $purple;
      }

      .row {
        margin-top: 2.5rem;
      }

      &.balance {
        svg {
          height: 2rem;
          margin-right: 0.5rem;
          filter: drop-shadow(0px 4px 13px $dark-blue);

          &.ctx-neon {
            filter: drop-shadow(0 0 0.25rem #6450fb);
          }
        }
        .row {
          margin-top: 0rem;
        }

        h3 {
          display: flex;
          align-items: center;
        }

        p {
          margin-top: 1rem;
        }
      }

      .btn {
        background-color: $darker;
        border-radius: 1px;
        height: 3.375rem;
        width: 100%;
        @media only screen and (min-width: 1100px) and (max-width: 1415px) {
          margin-bottom: 5px;
        }
      }

      form {
        margin-top: 1rem;

        label {
          font-family: "Nineteen Ninety Seven";
          color: $white;

          &.max {
            float: right;
            a {
              color: $green;

              &.orange {
                color: $orange;
              }
            }
          }
        }

        .text-muted {
          text-align: left;
          font-size: 1rem;
        }

        input[type="text"] {
          border-radius: 1px;
          background: no-repeat;
          border-color: $dark;
          color: $white;
          height: 3.375rem;
        }

        .btn {
          background-color: $darker;
          // width: 3.375rem;
          border-radius: 1px;
          height: 3.375rem;
          width: 100%;
        }

        .remove {
          margin-top: 3.5rem;
        }
      }

      small {
        color: white;
        filter: drop-shadow(0 0 5px #a440f2);
      }

      &.pool {
        small {
          color: white;
        }

        .incentive {
          margin-top: -22px;
          margin-left: 20px;
          height: 20px;
          & > path {
            fill: $white !important;
          }
        }
      }
      &.univ3, &.liquidity {
        padding-bottom: 1rem;
        width: 100%;
        p {
          width: 70%;
        }
        .btn {
          width: 11.0625rem;

          &.neon-green {
            box-shadow: 0px 0px 39px -6px rgba(63, 217, 180, 0.4);
          }
        }
        .btn:disabled {
          color: $white;
        }

        .table {
          td {
            a.uniswap {
              color: #ff007a;
            }
             a.btn {
              padding-top: 0.4rem;
            }
            .end-date {
              font-size: 0.8rem;
              color: $light;
            }
          }

          .btn {
            width: 80%;
            height: 2.5rem;
            padding: 0rem;
            width: 5rem;
            margin-left: auto;
            margin-right: auto;

            &.question {
              border-radius: 2px;
              width: 20px;
              height: 20px;
              margin-right: 0px;
              font-size: 10px;
              padding: 2px;
              margin-left: 5px;
            }
          }

          .claim-vest {
            font-size: 0.8rem;
            @media (min-width: 1300px) {
              width: 7rem;
            }
          }

          thead {
            .rewards {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .btn {
              width: 37px;
              min-width: 35px;
              height: 35px;
              border-radius: 0.25rem;
              background-color: $dark;
              margin-top: 5px;
              margin-left: 7px;
            }
            @media (max-width: 1500px) {
              .title {
                flex: 1;
              }
              .button {
                flex: 2;
              }
            }
            @media (max-width: 1000px) {
              .title-current {
                flex: 1;
              }
              .button-current {
                flex: 2;
              }
            }
          }
        }

        .univ3-header {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          padding-left: 0rem;            
        }

        .univ3-body {
          padding: 1rem 0rem;
          thead {
            .btn {
              margin-top: 0rem;
              margin-bottom: 0rem;
            }
          }
          tbody{
            border-bottom: 1px solid #a440f2;
            .status {
              font-family: "Nineteen Ninety Seven";
              font-size: 15px;
              color: #fff !important;
            }
          }
          th.right {
            text-align: right;
          }
          th.center {
            text-align: center;
          }
          th.status {
            width: 15%;
          }
          .btn {
            width: 7rem;
            margin-left: 1.5rem;
          }
          .btn.small {
            width: 5rem;
            @media (max-width: 1550px) { 
              width: 7rem;
            }
          }
          .btn.claim {
            background-color: #281e31;
            border-radius: 5px;
            height: 2.2rem;
            width: 6rem;
            margin-right: 1rem;
            padding: 0.1rem 1rem;
          }
          .claim-button2 {
            .btn.claim {
              margin-right: 0rem;
            }
          }
          .btn.position-url {
            border-width: 1px;
            border-color: $bright-green;
          }
          .number {
            font-size: 1rem;
            color: $white;
            text-shadow: 0 0 4px #f195f9,0 0 10px #fff,0 0 20px #e440f2,0 -10px 40px #b626c4;
          }       
          .current-rewards {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .fire {
            .number {
              font-family: "Nineteen Ninety Seven";
            }
          }
          h6 {
            color: $white;
            margin-top: 3px;
            margin-right: 15px;
            font-size: 1.1rem;
          }
          .rewards {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;

            h6 {
              margin: 0rem;
              margin-right: 0.4rem;
            }
            .btn {
              margin-left: 0.5rem;
            }
            &-item {
              display: flex;
              padding-right: 2.2rem;
              align-items: center;              
            }
            &-tier {
              display: flex;
              flex-direction: row;
              align-items: center;              
            }
            &-total {
              display: flex;
              flex-direction: row;
              align-items: center;
              &-heading {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
              &-claim {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
              .btn {
                margin-left: 0rem;
              }
              .amount {
                display: flex;
                
                svg {
                  margin-top: -3px;
                  margin-left: 4px;
                }
              }
            }            
          }
          .position {
            padding-right: 4rem;
            .description{
              display: flex;
              align-items: center;
              font-weight: bold;
              small {
                margin-left: 10px;
              }
            }
            .tokens {
              font-size: 0.9rem;
              color: $purple ;
            }            
            .ranges {
              display: flex;
              width: 100%;
              flex-direction: row;
              align-items: center;
              .min-range {
                font-size: 1rem;
                margin-right: 2.5rem;
              }
              .max-range {
                font-size: 1rem;
                margin-left: 2.5rem;
              }
              @media (max-width: 1350px) {
                .min-range {
                  margin-right: 1rem;
                }
                .max-range {
                  margin-left: 1rem;
                }
              }
            }
          }          
        }

        .empty-lp {
          display: flex;
          flex-direction: column;
          align-items: center;

          .lp-box {
            border: 1px solid $light;
            border-radius: 5px;
            background-color: #281e31a3;
          }

          .lp-info {
            display: flex;
            flex-direction: column;
            padding: 15px;

            .row1 {
              display: flex;
              flex-direction: row;
            }

            .row2 {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: 0.6rem;

              h6 {
                font-size: 0.9rem;
                margin: 0rem;
              }

              .number {
                font-size: 0.85rem;
                margin-left: 0.3rem;
              }

              .neon-pink {
                margin-left: 0.3rem;
                font-size: 0.9rem;
                text-shadow: 0 0 4px #f195f9, 0 0 10px #fff, 0 0 20px #e440f2, 0 -10px 40px #b626c4;
              }
            }
          }

          .icons {
            display: flex;
            align-items: center;
            padding: 5px;
            padding-top: 0px;
          }

          .description {
            margin-left: 10px;
            padding: 5px;
          }

          .uni {
            margin-left: -10px;
            filter: drop-shadow(0 0 5px #a440f2);
          }

          .btn-box {
            width: 100%;
          }

          .btn {
            font-size: 0.9rem;
            height: 2.5rem;
            width: 100%;
            border: 0px;
            border-top: 0.8px solid $light;
            background-color: #281e31;
            margin: 0px;
            padding-top: 8px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          .btn:hover {
            color: $white;
          }

          small {
            margin-left: -10px;
          }
        }

        .unstaked {
          text-shadow: 0 0 4px #7940f2, 0 0 10px #fcdf49, 0 0 20px #f28040, 0 -10px 40px #c33;
        }

        .deposited {
          text-shadow: 0 0 4px #167226, 0 0 10px #61c4b3, 0 0 20px #167226, 0 -10px 40px #167226;
        }

        .staked {
          text-shadow: 0 0 4px #e440f2, 0 0 10px #e98ff1, 0 0 20px #e440f2, 0 -10px 40px #b626c4;
        }

        .not_approved {
          text-shadow: 0 0 4px #eeb173, 0 0 10px rgb(236, 97, 97), 0 0 20px #eeb173, 0 -10px 40px #c33;
        }

        .out_range {
          text-shadow: 0 0 4px rgb(233, 42, 42), 0 0 10px rgb(236, 97, 97), 0 0 20px rgb(233, 42, 42), 0 -10px 40px #c33;
        }
      }
      th {
        vertical-align: middle;
      }
    }
    .card {
      svg {
        height: 2rem;
      }
      svg.stake {
        filter: drop-shadow(0px 0px 5px rgba(191, 245, 225, 0.7));
      }

      svg.h24 {
        filter: drop-shadow(0px 0px 5px rgba(6, 180, 255, 0.53));
      }

      svg.tcap {
        width: 3rem;
      }

      svg.weth {
        filter: drop-shadow(0px 0px 5px rgba(111, 19, 202, 0.53));
        border-radius: 30px;
        border-color: #ac3eff;
        border-style: solid;
        border-width: 1.8px;
        width: 32px;
        height: 32px;
        padding-top: 2px;
        padding-bottom: 2px;
        margin-top: 2px;
        margin-left: 2px;
        margin-right: 2px;
      }

      svg.wbtc {
        filter: drop-shadow(0px 0px 5px rgba(249, 252, 73, 0.42));
      }

      svg.dai {
        filter: drop-shadow(0px 0px 5px rgba(242, 128, 64, 0.42));
      }

      svg.tcap {
        margin-left: 0.5rem;
        width: 35px;
        height: 35px;
        @media (max-width: 1480px) {
          margin-left: 0rem;
          margin-top: 5px;
        }
      }

      svg.ctx-neon {
        width: 32px;
        height: 32px;
        width: 35px;
        height: 35px;
        margin-right: 0.5rem;
      }
    }
    .question-small {
      border-radius: 2px;
      width: 20px !important;
      height: 20px  !important;
      margin-right: 0;
      font-size: 10px;
      padding: 2px;
      margin-left: 5px;
      margin-bottom: 0px !important;
    }
  }

  @media (max-width: 1370px) {
    width: 115%;
  }

  @media (max-width: 1280px) {
    width: 125%;
  }

  @media (max-width: 1200px) {
    width: 138%;
  }

  @media (max-width: 1070px) {
    width: 155%;
  }

  @media (max-width: 1000px) {
    width: 170%;
  }

  @media (max-width: 930px) {
    width: 180%;
  }

  @media (max-width: 1050px) {
    .card-wrapper {
      .card {
        overflow-x: scroll;
      }
    }
  }

  @media (max-width: 1100px) {
    .card-wrapper {
      .card {
        &.diamond {
          .btn {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  @media (max-width: 850px) {
    width: 100%;
    padding-top: 4rem;
    margin-left: 2rem;

    #connect {
      width: 12rem;
    }

    .card-wrapper {
      .card {
        padding: 3.125rem 1.8125rem;
      }
    }
  }

  @media (max-width: 850px) {
    margin-top: 2rem;
    padding: 1rem;    

    .card-wrapper {
      margin: 0rem;
      .card {
        background-color: transparent;
        padding: 0rem;
        overflow-x: unset;

        &-header {
          h3 {
            font-size: 1.2rem;
          }
        }        
      }
    }
    
    .rewards-mobile {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      background-color: $darker !important;
    }

    .box {
      display: flex;
      justify-content: space-between;
      margin: 0.75rem 0rem;
      border: 0.5px solid $highlight;
      border-radius: 5px;

      .title {
        display: flex;
        width: 45%;
        padding: 0.5rem;
        background-color: $darkest;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        &-price {
          font-size: 0.85rem;
        }
      }

      .value {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 55%;
        padding: 0.5rem;
      }
    }

    .rewards {
      display: flex;
      flex-direction: column;

      &-tier {
        flex-direction: column;
        align-items: flex-start;
      }
      &-item {
        .title { 
          width: 55%;
        }
        .value {
          width: 45%;
        }
      }

      &-total {
        margin-top: 1.5rem;
        &-heading {
          display: flex;
          flex-direction: row;
        }
        &-claim {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 0.5rem;
          border: 0.5px solid $highlight;
          border-radius: 5px;
          .amount {
            display: flex;
            align-items: center;
            width: 55%;
            height: 100%;
            padding-left: 0.7rem; 
          }
          .claim-button {
            width: 45%;
            .btn {
              height: 3rem;
              width: 100% !important;
              margin: 0rem !important;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }    

    .empty-lp {
      margin-top: 2rem;
      
      .lp-box {
        width: 95%;
      }
      .lp-info {
        padding-bottom: 1.6rem !important;
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem !important;
      }
    }

    .positions-mobile {
      svg {
        color: white;
      }
      .position {
        padding: 1rem !important;
        background-color: $darker;

        .card-header {
          padding: 0rem;
        }
        .card-body {
          padding: 0rem;
          padding-top: 1rem;
        }
        &-title {
          display: flex;
          align-items: center;          
        }
        
        .title {
          display: flex;
          align-items: center;
        }
        .description {
          display: flex;
          flex-direction: row;
          margin-bottom: 1rem;
          svg {
            color: $highlight;
          }
          .title {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
        }
        .icons {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 1rem;

          svg {
            margin-top: 0rem;
          }

          .weth {
            margin-right: 0.5rem;
          }
        }
        .ranges {
          align-items: center;
          padding: 0.5rem;
          border: 0.5px solid $highlight;
          border-radius: 5px;          
        }        
        .status {
          .value {          
            display: flex;
            font-family: "Nineteen Ninety Seven";
          }
        }

        &-actions {
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 0.5rem;
          padding: 1rem 0rem;
          .btn {
            height: 3rem;
            width: 95% !important;
            margin: 0rem !important;
          }
        }
      }
    }

    .liquidity {
      margin-top: 4rem !important;

      &-reward {
        background-color: $darker !important;
        margin-bottom: 1.5rem;

        &-header {
          display: flex;
          align-items: center;
          .icons {
            margin-right: 1rem;
          }
        }
        &-actions {
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 0.5rem;

          .btn {
            height: 3rem !important;
            width: 95% !important;
            margin: 0rem !important;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    margin-left: 0rem;
  }
}
