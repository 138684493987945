@import "./colors";
@import "./fonts";



.modal-content {
  padding: 2rem;
  background-color: $primary;
  background-clip: padding-box;
  border: 1px solid $highlight;
  box-sizing: border-box;
  box-shadow: 0px 0px 39px -6px rgba(164, 64, 242, 0.4);
  border-radius: 2px;

  .green & {
    border: 1px solid $green;
    box-shadow: 0px 0px 39px -6px rgba(63, 217, 180, 0.4);
  }

  b {
    color: $purple;
  }

  .modal-header,
  .modal-footer {
    border: 0px;
  }

  .modal-header {
    .btn-close {
      height: 20px;
      width: 20px;
      background-color: transparent;
      background-image: url("../assets/images/x-symbol.svg");
      background-size: cover;
      background-repeat: no-repeat;
      border: none;
      margin-top: -1rem;
      margin-right: -1rem;

      @media (max-width: 900px) {
        margin-top: -0.3rem;
        margin-right: -0.3rem;
      }
      @media (max-width: 900px) {
        height: 15px;
        width: 15px;
      }
    }    
  }

  .modal-title {
    font-family: "Nineteen Ninety Seven";
    color: $white;
  }

  .btn {
    background-color: $darker;
    width: 100%;
    border-radius: 1px;
    height: 3.375rem;

    &:hover {
      color: $white;
    }
    margin-bottom: 0.5rem;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    li {
      color: $light;
    }
  }

  .delegate-description {
    margin-bottom: 3rem;
  }

  form {
    // padding: 2rem 2rem 0rem 2rem;
    margin-top: 1rem;

    label {
      font-family: "Nineteen Ninety Seven";
      color: $white;

      &.max {
        float: right;
        a {
          color: $bright-green;

          &.orange {
            color: $orange;
          }
        }
      }
    }

    .avatar {
      position: absolute;
      height: 92%;
      width: 65%;
      margin-left: 20px;
      cursor: pointer;
      z-index: 0;
    }

    .image-group {
      display: flex;
      padding-left: 0px;
      padding-right: 0px;
      margin-bottom: 15px;
      .col-lg-6, .col-md-6, .col-lg-8, .col-md-8, .col-md-12, .col-lg-12 {
        padding-left: 0px;
        padding-right: 0px;
      }
      a {
        position: absolute;
        margin-left: 35px;
      }
    }

    .text-muted {
      text-align: left;
      font-size: 0.8rem;
    }
    .text-muted.liquidation-fee,
    .text-muted.liquidation-reward {
      color: #9ca5ad !important;
      font-size: 0.9rem;
    }
    .liquidation-data {
      display: flex;
      margin-top: 5px;
      justify-content: space-between;

      .reward-usd {
        padding-left: 25px;
      }
    }

    input[type="text"].neon-green {
      border-color: $bright-green !important;
      border-radius: 1px;
      background: no-repeat;
      border-color: $dark;
      color: $white;
      height: 3.375rem;
    }

    input[type="text"].neon-orange {
      border-color: $orange !important;
      color: $white;
    }

    .custom-file {
      height: 87%;
      width: 80%;
    }

    input[type="file"] {
      background-color: transparent;
      border: none;
      color: transparent;
      height: 100%;
      width: 90%;
      margin-left: 20px;
      cursor: pointer;
      z-index: 1;      
    }
    input[type="file"]:focus {
      box-shadow: none;
    }

    input::file-selector-button {
      background-color: transparent;
      color: transparent;
      border: none;
    }

    textarea.neon-green {
      border-color: $bright-green;
      border-radius: 1px;
      background: no-repeat;
      color: $white;
    }

    textarea.neon-orange {
      border-color: $orange;
      background: no-repeat;
    }

    .custom-file {
      label {
        color: $light;
        font-family: "Space Mono";
      }
    }
    .btn {
      background-color: $darker;
      border-radius: 1px;
      height: 3.375rem;
      width: 100%;
    }
    .remove {
      margin-top: 3.5rem;
    }

    .liquidate-input {
      .form-control {
        z-index: 1;
      }
      .text-muted {
        position: absolute;
        width: 100%;
        text-align: right;
        font-size: 0.9rem;
        padding: 0.7rem 0.75rem 0.375rem 0.75rem;
      }
    }
  }

  table.liq-info {
    margin-top: 3rem !important;
    .btn-dark {
      padding-top: 5px;
      padding-left: 10px;
    }
    th {
      text-align: right;
      border-bottom: 1px;
    }
    td {
      text-align: right;
    }
    .net-reward {
      font-size: 18px;
      text-shadow: 0 0 4px #e440f2,0 0 10px #e98ff1,0 0 20px #e440f2,0 -10px 40px #b626c4;
    }
  }

  .liq-info.mobile {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .box {
      display: flex;
      flex-direction: row;
      border: 0.5px solid $off-white;
      border-radius: 5px;
      margin-top: 1rem;

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem;
        width: 50%;
        background-color: $darkest;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-size: 0.8rem;
        color: white;
      }
      .value {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0.5rem;
        width: 50%;
        font-size: 0.8rem;
        color: white;
      }
      .net-reward {
        font-size: 0.9rem;
        text-shadow: 0 0 4px #e440f2, 0 0 10px #e98ff1, 0 0 20px #e440f2, 0 -10px 40px #b626c4;
      }
    }
  }

  .progress {
    height: 2rem;

    .progress-bar {
      font-weight: bold;
    }
  }
  .warning-span {
    color: #f28040;
    font-size: 13px;
  }
  .success-span {
    color: #38d6b2;
    font-size: 13px;
  }

  .change-network {
    .btn-polygon {
      padding-left: 0rem;
    }
    svg {
      height: 1.7rem;
      width: 1.7rem;
      margin-right: 1.1rem;
      filter: brightness(1118%) drop-shadow(0px 0px 5px #a440f2);
      
      .polygon {
        margin-right: 0.7rem;
      }
    }
  }

  .change-network {
    .btn-polygon {
      padding-left: 0rem;
    }
    svg {
      height: 1.7rem;
      width: 1.7rem;
      margin-right: 1.1rem;
      filter: brightness(1118%) drop-shadow(0px 0px 5px #a440f2);
      
      .polygon {
        margin-right: 0.7rem;
      }
    }
  }

  @media (max-width: 1000px) {
    .modal-body {
      overflow-x: scroll;
    }
  }

  @media (max-width: 1000px) {
    padding: 0.5rem;

    .modal-body {
      padding: 0rem 0.5rem;
    }
    .modal-footer {
      padding: 0.5rem;
    }
  }
}

@media (max-width: 900px) {
  .modal-dialog {
    max-width: 600px;
  }
}