$primary: #1d1d3c;
$highlight: #a440f2;
$neon-highlight: rgba(223, 33, 254, 0.5);
$purple: #7940f2;
$dark: #494866;
$darker: #212138;
$darkest: #281e31;
$light: #9795b0;
$lighter: #d6d5e3;
$white: #ffffff;
$off-white: #f5f5f5;
$pink: #e440f2;
$orange: #f28040;
$blue: #0fd9ff;
$dark-blue: #2620fb;
$green: #38d6b2;
$bright-green: #4dd70c;
$yellow: rgb(252, 223, 73);
$background: #141429;
