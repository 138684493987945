@import "./colors";
@import "./fonts";

.Toastify {
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .Toastify__toast {
    padding: 0;
    padding-bottom: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .Toastify__toast-container {
    width: 26.25rem;
    margin-top: 3rem;
    margin-right: 3rem;

    @media (max-width: 480px) {
      width: 22.25rem;
      margin-right: 0rem;
      margin-left: 1rem;
    }
  }

  .body {
    background-color: $primary;
    border: none;
    color: white;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 1rem;
  }

  .Toastify__toast {
    border: 1px solid $highlight;
    border-top: none;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px #f124ff, 0px 0px 10px rgba(241, 36, 255, 0.24);
    border-radius: 4px;
    border-top-left-radius: 0;
    background: linear-gradient($highlight, $primary);
    border-top-right-radius: 0;
    color: $white;

    &.error {
      border: 1px solid $orange;
      background: linear-gradient($orange, $primary);
      box-shadow: 0px 0px 4px $orange, 0px 0px 10px rgba(242, 128, 64, 0.24);
    }

    &.success {
      border: 1px solid $green;
      background: linear-gradient($green, $primary);
      box-shadow: 0px 0px 4px $green, 0px 0px 10px rgba(112, 235, 183, 0.24);
    }

    .Toastify__close-button {
      color: $white;
      opacity: 1;
    }

    h5 {
      font-size: 1rem;
    }

    p {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    button {
      align-self: flex-end;
      margin-right: 0.5rem;
      margin-bottom: 0.2rem;
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 0 rgba(164, 64, 242, 0);
      }
    }

    img.toasty {
      width: 3.3rem;
      float: left;
      margin-right: 1rem;
    }
  }
  p {
    margin-left: 4.3rem;
  }
}
