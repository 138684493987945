.loading-modal {
  height: 85%;
  display: grid;
  align-content: center;
  align-self: center;
  justify-content: center;

  h4 {
    text-align: center;
  }

  img {
    margin: 10px auto;
  }
  p {
    text-align: center;
  }
}

.loading-modal.total {
  height: 100%;
}

.breathing-icon {
  -webkit-animation: breathing 1s ease-out infinite normal;
  animation: breathing 1s ease-out infinite normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
