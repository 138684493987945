@import "./colors";

.delegation {
  padding: 1rem 2rem;
  margin-top: 0rem;

  @media (max-width: 450px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .nodisplay{
    display: none;
  }

  &.off { 
    margin-top: 0rem;
  }

  .create {
    width: 15rem;
  }

  .grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: none;
    gap: 2rem;
    margin-bottom: 2rem;
    @media (min-width: 1700px) {
      grid-template-columns: auto auto auto;
    }
    @media (max-width: 900px) {
      grid-template-columns: auto;
    }
  }
  svg {
    height: 22px;
    margin-right: 10px;
    margin-left: -5px;
    margin-top: -5px;
  }

  .card {
    margin-top: 2rem;
    width: 35rem;
    border-radius: 2rem;

    &-body {
      padding-bottom: 0rem;
    }
    &-footer{
      padding-top: 0rem;
      padding-bottom: 0rem;
      .buttons-container {
        display: flex;
        flex-direction: row;
      }
    }
    &-title {
      display: flex;
      margin-top: 1rem !important;
      justify-content: flex-end; //...
      a {
        color: $white;
      }
      .title-names {
        display: flex;
        flex-direction: column; 
        text-align: end; //..
      }
      .title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        h5 {
          flex: 2;
          text-align: end;
        }
        .btn {
          flex: 1;
          border: none !important;        
          text-align: end;
          padding-right: 0rem;
        }
        .btn:focus {
          border: none;
          box-shadow: none;
        }
        .btn:hover {
          background-color: transparent;
        }
      }
    }
    &-text {
      margin-bottom: 1rem;
    }
    .badges-container {
      display: flex;
      margin-top: 10px;
      
      .badges-container2 {
        display: flex;
        flex-wrap: wrap;        
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      padding: 1rem 0rem 0rem 0rem;
      margin-top: -90px;

      .why {
        h5 {
          flex: 1;
        }
        &-title {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          margin-bottom: 8px;
        }
        .accounts {
          display: flex;
          flex: 2;
          justify-content: flex-end;
          .badge {
            margin-bottom: 0rem;
          } 
        }
        p {
          text-align: justify;
        }
      }
      .columns {
        display: flex;
        flex-direction: row;
        div {
          padding: 5px 0px;
        }
        @media (max-height: 850px) {
          margin-bottom: 1rem;
        }
      }
      &-col1 {
        padding-right: 10px !important;
      }
      &-col2 {
        text-align: end;
      }
    }
    @media (max-height: 850px) {
      p {
        margin-bottom: 0rem;
      }
    }
  }

  .diamond {
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    height: 7rem;
    background-image: url("../assets/images/profile-header.png");
    background-repeat: repeat;
    background-position: right bottom;
    background-size: contain;
    background-color: $purple;
    @media (max-height: 850px) {
      height: 6rem;
    }
  }

  .badge.bg-light {
    //border: 1px solid $highlight;
    border-radius: 25px;
    height: 30px;
    border-color: $off-white;
    background-color: $off-white;
    color: #a440f2;
    padding: 10px;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .avatar {
    height: 130px;
    width: 130px;
    margin-top: -80px;
    border-radius: 50%;
    @media (max-height: 850px) {
      height: 120px;
      width: 120px;
    }
  }

  .blockie {
    height: 130px !important;
    width: 130px !important;
    border-radius: 50%;
    margin-top: -80px;
  }
  
  .continue {
    text-shadow: 0 0 4px #7940f2,0 0 10px #fcdf49,0 0 20px #f28040,0 -10px 40px #c33;
    font-size: 22px;
    -webkit-animation: fire 2s infinite alternate;
    animation: fire 2s infinite alternate;
    color: #d9d7ea !important;
  }

  .discord {
    width: 15px;
    margin-right: 2px;
    margin-top: -2px;
  }

  .ethereum {
    width: 15px;
    margin-right: 2px;
    margin-top: -2px;
  }
  .tally {
    width: 15px;
    margin-right: 2px;
    margin-top: -7px;
  } 
  .twitter {
    height: 15px;
    margin-right: 2px;
    margin-top: -1px;
  }

  .github {
    width: 15px;
    margin-right: 2px;
    margin-top: -2px;
  }

  h5 {
    font-size: 1rem;
    color: white;
    clear: both;
  }

  .btn {
    min-height: 3.375rem;
    &:hover, &:active, &:focus {
      color: white;
    }
    @media (max-height: 850px) {
      min-height: 3rem;
    }
  }

  label{
    color:$white;
  }

  .holder-stake {
    &-content {
      display: flex;
      flex-direction: row;

      .remove {
        flex: 1;
        padding-top: 0.3rem;
        text-align: end;
        a {
          color: $orange;
          font-size: 0.8rem;
          font-family: "Nineteen Ninety Seven";
        }        
      }
    }
  }

  .staker-wrapper {
    margin-top: 2rem;
    padding-left: 1rem;
    h2 {
      font-size: 1.375rem;
      margin-bottom: 1.5625rem;
    }

    h3 {
      font-size: 1rem;
    }

    p {
      font-size: 0.875;
    }

    .btn {
      background-color: $darker;
      border-radius: 1px;
    }

    .staker {
      background-color: $darker;
      width: 100%;
      padding: 2.125rem 2.8125rem;
      border-radius: .25rem;
      .table {
        color: $white;
        tr:hover {
          color: $white;
        }
        td {
          vertical-align: middle;

          a {
            font-weight: bold;
            color: $purple;
          }

          a.uniswap {
            color: #ff007a;
          }

          .end-date {
            font-size: 0.8rem;
            color: $light;
          }
          &.vested-reward {
            padding-top: 2.2rem;
          }
        }
        .btn {
          width: 80%;
          min-height: 2.5rem;
          height: 2.5rem;
          padding: 0rem;
          width: 5rem;
          margin-left: auto;
          margin-right: auto;

          &.question {
            border-radius: 2px;
            width: 20px;
            height: 20px;
            margin-right: 0px;
            font-size: 10px;
            padding: 2px;
            margin-left: 5px;
          }
          thead {
            .btn {
              width: 37px;
              min-width: 35px;
              height: 35px;
              border-radius: 0.25rem;
              background-color: $dark;
              margin-top: 5px;
              margin-left: 7px;
            }
            .rewards {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }
        }

        .end-date {
          text-align: right;
        }
      }
      @media (max-width: 450px) {
        padding: 2rem 1rem;
      }
    }
  }

  .keepers-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $darker;
    margin-top: 1rem;
    padding: 1.5rem;
    .sort-box {
      display: flex;
      align-items: center;
    }

    .dropdown {
      margin-left: 0.5rem;
      .dropdown-toggle {
        min-height: 2.5rem;
        height: 2.5rem;

        span {
          font-size: 0.8rem;
        }
      }
      .sort-by-toggle {
        margin-right: 0.5rem;
      }
    }

    .create {
      .btn {
        min-height: 2.7rem;
      }
    }
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    .staker-wrapper {
      width: 90%;
      padding-left: 0rem;
    }
    .keepers-options {
      width: 90%;
    }
    .grid {
      justify-items: center;
    }
    .card {
      width: 90%;
    }
  }

  @media (max-width: 600px) {
    .card {
      width: 100%;
    }
    
    .staker-wrapper {
      width: 100%;
      .staker {
        width: 100%;
      }
    }

    .keepers-options {
      width: 100%;
    }

    .stake-reward-mobile {
      h2 {
        margin-bottom: 0rem;
      }
      .btn {
        width: 100%;
        margin-left: 0rem !important;
        margin-top: 1rem;
      }
      .accordion-button::after {
        margin-top: 0.2rem;
      }
      .accordion-body {
        margin-top: 2rem;
      }
    }

    .keepers-options {
      flex-direction: column;
      align-items: flex-start;

      .search-box {
        width: 100%;
        margin-bottom: 1.5rem;

        .sort-box {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .titles {
          width: 35%;
        }
        .dropdown {
          width: 80%;
        }
      }

      .create {
        width: 100%;
      }      
    }
  }
}

.cryptkeeper-form {
  .avatar {
    border: 0.8px solid $green;
  }
  .add-file-icon {
    svg {
      background:rgba(0, 0, 0, 0.175);
      border: 3px solid rgba(209, 194, 239, 0.65);
      color: rgba(209, 194, 239, 0.65);
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }
  }

  @media (max-width: 600px) { 
    .image-group {
      margin-bottom: 2rem !important;

      .keeper-names {
        width: 30rem;        
      }
      
      a {
        margin-left: 0rem !important;
      }
    }
  }
}
